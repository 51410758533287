<template>
  <div class="content">
    <PageHeader title="Detalhe da Fatura" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="invoice-wrapper">
                <div class="invoice-header border-bottom">
                  <div class="row">
                    <div class="col-sm-12 col-md-6 hide-sm">
                      <h1>Fatura</h1>
                    </div>
                    <div class="col-sm-12 col-md-6 text-right">
                      <div class="logo-wrapper">
                        <img src="https://yup.chat/assets/img/logo.svg" width="200">
                      </div>
                      <address class="address m-t-10">
                        São Paulo e região<br>
                        Telefone: +55 11 4280 8546<br>
                      </address>
                    </div>
                  </div>
                    <div class="invoice-summary">
                  <div class="row">

                      <div class="col-sm-12 col-md-6">
                          <div class="sub-title">Para</div>
                          <address class="address">
                            Nome do Cliente.<br>
                            {{$t('billing.prefs.lbl-address')}}<br>
                            Brasília, Teste e Teste<br>
                            Telefone: +55 61 996426188<br>
                          </address>
                      </div>
                          <div class="col-sm-12 col-md-6 text-right">
                            <ul class="summary">
                              <li><span class="f-w-500">Fatura #:</span> #JKLSLLKKJSLK</li>
                              <li><span class="f-w-500">Data:</span> 24/04/2020</li>
                            </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="invoice-body">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>{{$t('departments.data-infos.lbl-description')}}</th>
                          <th class="text-right">QTD</th>
                          <th class="text-right">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Upgrade
                          </td>
                          <td class="text-right">1</td>
                          <td class="text-right">R$ 29,00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="invoice-footer">
                  <div class="invoice-total">
                    <div class="row">
                      <div class="col-12">
                        <p>Sub Total: R$ 29,00</p>
                        <p>0% Desconto: R$ 0,00</p>
                        <p class="total">Valor Total: <span class="text-success">R$ 29,00</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer bg-light">
              <button class="btn btn-primary pull-right m-t-20 m-b-20">Baixar Fatura</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
  },
  data() {
    return {
      dateRange: {},
      status: [
        { name: 'Pendente' },
        { name: 'Enviado' },
      ],
      formFilter: { status: '', startDate: null, endDate: null },
      toDay: {},
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Otu', 'Nov', 'Dez'],
        },
      },
      pFilter: null,
    };
  },
  mounted() {
    this.toDay = {
      date: new Date(),
    };
  },
  methods: {
    filter() {
      this.formFilter.startDate = this.dateRange[0];
      this.formFilter.endDate = this.dateRange[1];
      this.$root.$emit('sms.filter', this.formFilter);
    },
    exportSMS() {
      this.$root.$emit('sms.export', this.formFilter);
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id{
  height: 35px;
}
.btn-export{
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10{
  margin-top: 10px;
}
</style>
